.discovery-workshop-outer-container {
  // background-image: url("../../../public/images/general/backgrnd-workshop.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.discovery-workshop-container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 3rem 0;
}
.discovery-workshop-header {
  & p {
    width: 60%;
    text-align: center;
    margin: 0 auto !important;
  }
}
.discovery-workshop-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.discovery-workshop-list {
  width: 100%;
  margin: 1rem 0;
  gap: 1.4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // grid-template-areas:
  //   "card-1 card-1 card-2"
  //   "card-3 card-4 card-4";

  // .card-1 {
  //   grid-area: card-1;
  // }

  // .card-2 {
  //   grid-area: card-2;
  // }

  // .card-3 {
  //   grid-area: card-3;
  // }

  // .card-4 {
  //   grid-area: card-4;
  // }

  &-card {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    // box-shadow: $box-shadow;
    display: flex;
    border: 1px solid $border;
  }
  &-content {
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 1rem;
    & img {
      width: 50px;
      background-color: $icon-bg;
      border-radius: 10px;
      padding: 1rem;
    }
    & h3 {
      @extend .paragraphTitle;
    }
    p {
      padding: 0 !important;
      @extend .para;
    }
  }

  &-image {
    width: 40%;
    & img {
      width: 100%;
      border-radius: 10px;
    }
  }
}

.discovery-workshop-button-section {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  &-text {
    @extend .para;
    & span {
      font-weight: 700;
    }
  }
}
.discovery-workshop-button {
  width: fit-content;
  background-color: $blue !important;
  box-shadow: $box-shadow;

  & a {
    text-decoration: none;
    color: $white;
  }
}

@media screen and (max-width: 1280px) {
  .discovery-workshop-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .discovery-workshop-content-wrapper {
    gap: 1rem;
  }
  .discovery-workshop-header {
    & p {
      width: 100%;
    }
  }
  .discovery-workshop-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .discovery-workshop-button-section {
    flex-direction: row;
  }
  .discovery-workshop-button {
    width: 100%;
  }
}
