.innerpage-features-inner-container-description {
  @extend .para;
  text-align: center;
}

.innerpage-features-card-container {
  margin: 3rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // align-items: center;
  gap: 30px;
  justify-content: Center;
}
.innerpage-features-card-title {
  @extend .paragraphTitle;
  color: $blue;
  margin: 10px;
  padding: 0.5rem !important;
}
.innerpage-features-card-description {
  @extend .para;
  color: $blue;
  text-align: start;
}
.innerpage-features-card {
  padding: 1rem;
  position: relative;
  border-radius: 30px;
  margin: 10px;
  background-repeat: no-repeat;
  background-position: top left;
  transition: transform 0.5s;
}

.innerpage-features-card:hover {
  transform: scale(1.1);
  box-shadow: 0px 30px 120px 0px rgba(52, 62, 97, 0.1019607843);
}

.innerpage-features-card img {
  width: 70px;
  display: block;
  // margin: auto;
}

.innerpage-features-card h1 {
  font-size: 1.5em;
}

.innerpage-features-card p {
  color: rgba(1, 1, 1, 0.7);
  line-height: 1.8em;
}
.pointer {
  color: #fff;
}

.innerpage-features-card-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

@media screen and (max-width: 1280px) {
  .innerpage-features-card-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 768px) {
  .innerpage-features-card-container {
    grid-template-columns: 1fr;
  }
}
