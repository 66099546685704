.other-industries-solution-outer-container {
  // background-color: $background;
  //   background-image: url("../../../public/images/other-industries/light-bulb-with-white-splash-mixed-media.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 3rem 0;
}

.other-industries-solutions-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 3rem;
}
.other-industries-solution-card-text ol {
  padding-left: 15px;
  li {
    list-style: circle !important;
    padding: auto !important;
  }
}

.other-industries-solution-body-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin: 3rem 0;
}
.other-industries-solution-card {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  padding: 20px;
  border-radius: 20px;
  background-color: $foreground;
  box-shadow: $box-shadow;
  &-title h3 {
    padding-top: 5px !important;
  }
}

.other-industries-solution-icon svg {
  color: $brand-color;
}
