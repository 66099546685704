/*/ start count stats-counter /*/

.stats-counter-main-container#counter-stats {
  display: flex;
  justify-content: center;
}
.stats-counter-outer-container {
  background-color: $background;
}

.stats-counter {
  text-align: center;
  padding: 0 1rem !important;
  width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & h5 {
    @extend .para;
    padding: 0 !important;
  }
  &-description {
    @extend .para;
    width: 50%;
    margin: 0.5rem auto !important;
  }
  &-source {
    @extend .para;
    color: $brand-color;
    margin: 0.5rem auto !important;
  }
}

#stats-counter1,
#stats-counter2,
#stats-counter3 {
  border-right: 1px solid $border;
}

.stats-counter-numbers {
  @extend .bannerTitle;
  display: flex;
  justify-content: center;
  // background-image: linear-gradient(
  //   to right top,
  //   #343e64,
  //   #66447b,
  //   #a0417e,
  //   #d24169,
  //   #f05742
  // );
  // -webkit-text-fill-color: transparent;
  // -webkit-background-clip: text;
  color: $blue;
}
.stats-counter-row {
  width: 80%;
  background-color: $foreground;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 3rem auto;
  padding: 2rem;
  border-radius: 10px;
}

/*/ end count stats-counter /*/

@media screen and (max-width: 768px) {
  .stats-counter-row {
    flex-direction: column;
    align-items: center;
  }
  .stats-counter {
    max-width: 225px;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    &-description {
      width: 90%;
    }
  }
  .stats-counter-outer-container {
    margin-top: 2rem;
  }
  #stats-counter1,
  #stats-counter2,
  #stats-counter3 {
    border-bottom: 1px solid $border;
    border-right: none;
  }
}
