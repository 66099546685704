.info-table-body-container {
  display: flex;

  // grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 3rem;
}
.reverse-column {
  flex-direction: column !important;
}
.info-table-outer-container {
  background-color: $background;
}
.info-table-body-inner-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: 30px;
  width: 100%;
}
.info-table-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 20px;
  border-radius: 20px;
  background-color: $foreground;
  // box-shadow: 0px 30px 120px 0px rgba(52, 62, 97, 0.1),
  //   rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  &-text p {
    margin: 0;
    text-align: center;
  }
}
.info-table-body-title {
  position: relative;
  & h3 {
    padding: 0 !important;
    padding-left: 10px !important;
  }
  // &::before {
  //   content: "";
  //   position: absolute;
  //   width: 5px;
  //   height: 100%;
  //   background: #f05742;
  //   border-radius: 10px;
  // }
}
.info-table-body-icon img {
  // background-color: #f2eee3;
  width: fit-content;
  height: 400px;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
  display: flex;
}

.info-table-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
  max-width: 70%;
  margin: auto;
  margin-top: 3rem;
  & a {
    width: 30%;
  }
}
@media screen and (max-width: 768px) {
  .info-table-body-container {
    flex-direction: column;
  }
  .info-table-body-inner-container {
    display: block;
  }
  .info-table-footer {
    max-width: 100%;
  }
  .info-table-footer a {
    width: 100%;
  }
}
