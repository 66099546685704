@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&display=swap");
@import "../components/header/header";
@import "../components/header/headerMenu";
@import "./shared/common";
@import "../components/header/filler";
@import "../components/banner/homeBanner";
@import "./shared/button";
@import "../components/swimlinesection/valueprobs";
@import "./shared/common";
@import "../components/header/heademenuburger.scss";
@import "../components/testimonial/testimonial";
@import "../components/features/features";
@import "../components/articles/articles";
@import "../components/footer/footer.scss";
@import "../components/clients/clients";
@import "../components/features/innerPageFeatures";
@import "../components/resources/blog";
@import "../components/promo-video/promoVideo";
@import "../components/partners/partners";
@import "../components/partners/ourpartners/ourpartner";
@import "../components/company/about-us/teams/teams";
@import "../components/company/about-us/about-statement/about-stament";
@import "../components/company/about-us/core-value/core-values";
@import "../components/company/about-us/location/location";
@import "../components/review/review";
@import "../components/home-info-table/info-table";
@import "../components/company/career/careers";
@import "../components/company/career/form/careerform";
@import "../components/company/contact/contact";
@import "../components/company/contact/form/contactform";
@import "../components/company/contact/contactsupport";
@import "../components/no-page-found/no-page-found";
@import "../components/company/news/news";
@import "../components/statement/buisness-statement";
@import "../components/sign-up/signup";
@import "../components/platform/overview/infographic";
@import "../components/platform/voice-bot/voice-bot";
@import "../components/modal/modal";
@import "../components/resources/library/library";
@import "../components/generative-ai/generative-ai";
@import "../components/generative-ai/banner/gen-ai-banner";
@import "../components/modal/webinar-modal";
@import "../components/company/banner/company-banner";
@import "../components/resources/banner/resources-banner";
@import "../components/company/corporate-responsibility/corporate-responsbility";
@import "../components/solutions/banner/solution-banner";
@import "../components/solutions/customer-stories/customerStories";
@import "../components/stats-counter/stats-counter";
@import "../components/discovery-workshop/discovery-workshop";
@import "../components/value-proposition/value-proposition";
@import "../components/solutions/integration/integration-section";
@import "../components/accordion/accordion";
@import "../components/price-card/price-card";
@import "../components/newsletter/news-letter";
@import "../components/solutions/webinar/webinar";
@import "../components/modal/signup-modal";
@import "../components/products/banner/products-banner";
@import "../components/solutions/other-industries/other-industries";
@import "../components/resources/webinar";
@import "../components/why-choose-us/why-choose-us";
@import "../components/partners-logo/partners-logo";
@import "../components/industry-types/industy-type";
@import "../components/assistant-solutions/assistant-solutions";
@import "../components/demo-video/demo-videos";
@import "../components//redirect-page/redirect"; // @tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind base;

// @layer base {
//   blockquote,
//   dl,
//   dd,
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6,
//   hr,
//   figure,
//   p,
//   pre {
//     margin: 0.6rem 0;
//   }
//   img {
//     max-width: none;
//   }
//   svg,
//   video,
//   canvas,
//   audio,
//   iframe,
//   embed,
//   object {
//     display: inline;
//     vertical-align: middle;
//   }
// }
// ol,
// ul,
// menu {
//   list-style: unset;
// }