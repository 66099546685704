.redirect-page {
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      // background-color: $background;
      position: relative;
      border-radius: 30px;
      // border: 1px solid $border;
      box-shadow: $box-shadow;
      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   background-image: url("../../../public/images/general/bg-pattern.png");
      //   background-size: auto;
      //   background-position: center;
      //   opacity: 0.7; // Adjust the opacity as needed
      //   z-index: -1; // Ensure it is behind the content
      //   border-radius: inherit; // Match the border-radius of the container
      // }
    }
    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      padding: 2rem;
      & h2 {
        margin: 0 !important;
      }
    }
  }
  
  /// responsive design for the avatar-assistant component
  
  @media screen and (max-width: 768px) {
    .redirect-page {
      &-container {
        flex-direction: column;
      }
      &-content {
        padding: 1rem;
      }
    }
  }
  