.news-details-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 3rem;
  gap: 30px;
  padding: 10px;
}

.news-details-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-image: linear-gradient(
    to right top,
    #f4f4f4,
    #f7f7f7,
    #f9f9f9,
    #fcfcfc,
    #ffffff
  );
  border-radius: 20px;
  &:hover {
    box-shadow: 0px 30px 120px 0px rgba(52, 62, 97, 0.1019607843);
  }
  &-image img {
    border-radius: 20px;
    padding: 0;
  }
  &-title {
    margin: 0 10px !important;
    @extend .paragraphTitle;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &-description {
    margin: 0 10px !important;
  }
  &-link {
    text-decoration: none;
    color: $brand-color;
  }
  &-readmore {
    text-decoration: none;
    color: $brand-color;
    margin: 5px 10px;
  }
  &-image img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .news-details-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .news-details-container {
    grid-template-columns: 1fr;
  }
}
