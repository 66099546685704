.no-page-found-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
   margin: 1rem 0;
}
.no-page-found-description{
width: 50%;
text-align: center;
}