.home-banner-container {
  // padding-top: 20rem;?
  min-height: 550px;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 2rem 0;
  overflow: hidden;
  background-color: $background;
}
.home-banner-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right top;
  z-index: 1;
  scale: 1.1;
}
.home-banner-content {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative;
  padding: 60px 0;
  z-index: 1;
  h1 {
    margin: 0;
    font-size: 80px !important;
  }
  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url("../../../public/images/banner-images/banner-bg.png");
    background-repeat: no-repeat;
    background-position: top center;
    margin: 2rem;
    border-radius: 30px;
    padding: 20px 15px;
    &-description {
      width: 65%;
      @extend .para;
    }
  }
  .bannerPrimaryButton {
    color: #ffffff !important;
    background-color: $brand-color !important;
    border: 1px solid $brand-color !important;
  }
}

.home-banner-content-container-title {
  @extend .bannerTitle;
  // text-align: center;
  & span {
    position: relative;
    display: inline-block;
    background: linear-gradient(45deg, #f05742, #f05742);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  font-size: 67px !important;
}

.home-banner-recognization-section {
  width: 100%;
  margin: 3rem 0;
}

.home-banner-recognization-logos {
  display: flex;
  justify-content: center;
  gap: 80px;
  margin-top: 2rem;
  img {
    width: 150px;
  }
}

////// responsive design
/// for tablet

@media (max-width: 768px) {
  .home-banner-container {
    min-height: 400px;
    margin: 0;
   
  }
  .home-banner-content {
    width: 100%;
    padding: 30px 0;
    h1 {
      font-size: 50px !important;
      line-height: normal;
    }
    &-container {
      margin: 1rem; 
      &-description {
        width: 100%;
      }
    }
  }
  .home-banner-content-container-title {
    font-size: 40px !important;
  }
  .home-banner-recognization-logos {
    gap: 40px;
    img {
      width: 60px;
    }
  }
}
