.clients-container-title {
  @extend .sectionTitle;
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
}

.slider {
  width: 90%;
  margin: auto;
  height: var(--height);
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);

  .list {
    display: flex;
    animation: scroll infinite linear 30s;
    position: relative;
    gap: 1rem;
  }

  .item {
    flex: 0 0 var(--width);
    height: var(--height);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }

  &:hover .item {
    animation-play-state: paused !important;
    filter: grayscale(1);
  }

  &:hover .list {
    animation-play-state: paused;
  }

  .item:hover {
    filter: grayscale(0);
  }

  &.reverse .list {
    animation-direction: reverse;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50%));
  }
}
a.clients-container-title {
  @extend .sectionTitle;
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
}

.slider {
  width: 90%;
  margin: auto;
  height: var(--height);
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);

  .list {
    display: flex;
    animation: scroll infinite linear 10s;
    position: relative;
  }

  .item {
    flex: 0 0 var(--width);
    height: var(--height);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }

  &:hover .item {
    animation-play-state: paused !important;
    filter: grayscale(1);
  }

  &:hover .list {
    animation-play-state: paused;
  }

  .item:hover {
    filter: grayscale(0);
  }

  &.reverse .list {
    animation-direction: reverse;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% + var(--width)));
  }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .slider {
    --width: 80px !important;
    --height: 80px !important;
  }
}

@media (max-width: 768px) {
  .slider {
    --width: 50px !important;
    --height: 50px !important;
  }
}

@media (max-width: 480px) {
  .slider {
    --width: 30px !important;
    --height: 30px !important;
  }
}
