.testimonial-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonialItem-outer-container {
  max-width: 380px;
  min-height:310px;
 background-color: #ffffff;
 padding: 20px;
 border-radius: 20px;
}
.testimonialItem-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#testimonial-overlay-logo {
  position: absolute;
  top: -60px;
  width: 100px;
  left: 50px;
}
.testimonial-section-title {
  @extend .sectionMainTitle;
  margin: 0.2rem 0.1rem;
  color: $brand-color;
}
.testimonial-title {
  @extend .sectionTitle;
}
.testimonial-list-container {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 50px 0;
  background-color: $bg-color;
  border-radius: 20px;
  position: relative;
  z-index: -4;
}

.testimonialItem-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.testimonialItem-content-container {
  @extend .para;
}

.testimonialItem-about-container {
  @extend .para;
  font-weight: 600;
}
.testimonialItem-about-container span {
  @extend .para;
  font-size: 15px;
  color: $brand-color;
}
.testimonial-card-1 {
  position: relative;
  top: -40px;
  z-index: -1;
}
.testimonial-card-2 {
  position: relative;
  top: -80px;
  z-index: -2;
}
.ant-card-body {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media screen and (max-width: 768px) {
  .testimonial-list-container {
    display: flex;
    flex-direction: column;
  }
  .testimonialItem-outer-container {
    height: auto;
    overflow: hidden;
  }
  .testimonial-list-container {
    gap: 0px;
    background-color: transparent;
  }
}
