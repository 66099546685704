.webinar-footer-button {
  background: #f05742;
}

.resources-webinar-card {
  width: 100% !important;

  &-image {
    max-width: 100% !important;
  }
  button {
    background-color: $blue !important;
  }
}
.resources-webinar-card-image {
  height: auto !important;
}
.resources-webinar-card-title {
  color: $blue !important;
}
