.webinar-main {
  width: 80%;
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: $foreground;
  border-radius: 10px;
  margin: auto;
  padding: 0 2rem;
}
.webinar-cover-image {
  width: 100%;
  & img {
    border-radius: 15px;
    max-width: 100%;
  }
}
.webinar-main-title {
  background-color: $secondary-blue;
  width: fit-content;
  padding: 5px 20px !important;
  @extend .para;
  border-radius: 5px;
}
.webinar-content {
  width: 100%;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  text-align: start;
  &-title {
    @extend .sectionTitle;
    text-align: start;
  }
  &-tagline {
    @extend .para;
  }
  &-cta-button {
    width: fit-content;
    button {
      background-color: $brand-color !important;
    }
  }
}



.webinar-outer-container {
  background-color: $background;
}

@media screen and (max-width: 768px) {
  .webinar-main {
    flex-direction: column;
    gap: 10px;
    padding: 0 0.5rem;
  }
  .webinar-content {
    margin: 0;
    padding: 0;
  }
}
