.signup-modal-container {
  width: 80%;
  padding: 5px;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
}
.signup-modal-close {
  position: fixed;
  top: 0%;
  right: 1%;
  font-size: 30px;
  color: $brand-color !important;
  cursor: pointer;
  z-index: 1001;
}

@media only screen and (max-width: 1280px) {
  .signup-modal-container {
    width: 95%;
  }
}
