.products-banner-container {
  display: flex;
  align-items: center;
}
.products-banner-left,
.products-banner-left-inflow {
  width: 100%;
  margin: 3rem 5rem;
  padding: 2rem;
  border-radius: 15px;
  span {
    color: $brand-color;
  }
}
.products-banner-right {
  width: 100%;
  &-image {
    width: 100%;
    & img {
      width: 100%;
      border-radius: 20px;
    }
  }
}
.products-banner-button {
  margin-top: 2rem;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  & button {
    background-color: $blue;
  }
}
.products-banner-container-inflow {
  display: flex;
  justify-content: space-between;
}
.inflow-meta-image {
  width: 100px;
  margin: 1.5rem 1rem 1rem 0rem;
}
.products-banner-right-inflow {
  width: 40%;
}
.products-banner-right-image-inflow img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1280px) {
  // .products-banner-outer-container {
  //   position: static;
  // }
  .products-banner-left {
    width: 90%;
    margin: auto;
    position: relative;
    z-index: 1;
    box-shadow: none;
    padding: 3rem;
  }
  .products-banner-right {
    position: absolute;
    top: 35px;
    right: 0;
    &-image {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .products-banner-container-inflow {
    display: flex;
    flex-direction: column;
  }
  .products-banner-left-inflow {
    background-color: #ffffff;
    width: 100%;
    box-shadow: none;
    padding: 1rem;
    margin: 0;
    & p {
      color: $blue;
    }
  }
  .products-banner-left {
    width: 100%;
    margin: 0;
    position: relative;
    z-index: 1;
    box-shadow: none;
    padding: 1rem;
  }
  .products-banner-right {
    position: absolute;
    top: 35px;
    right: 0;
  }
  .products-banner-right-inflow {
    width: 100%;
  }
}
