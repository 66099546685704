.infographic-section {
  &-main {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }

  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  & div {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    & img {
      width: 90%;
      box-shadow: 0px 30px 120px 0px #343e6121;
    }
  }

  &-col-1 {
    justify-content: center;
  }
  &-col-2 {
    margin: 20px 0;
    justify-content: flex-end;
  }
  &-col-3 {
    justify-content: flex-start;
  }
  &-col-4 {
    justify-content: flex-end;
    margin: 20px 0;
  }
}

@media only screen and (max-width: 768px) {
  .infographic-section {
    & div {
      gap: 15px;
    }
    
    &-col-2 {
      margin: 10px 0;
      justify-content: flex-end;
    }
    &-col-4 {
      justify-content: flex-end;
      margin: 10px 0;
    }
  }
}
