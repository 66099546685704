.demo-video-container {
  position: relative;
  display: flex;
  gap: 30px;
  width: 80%;
  margin: auto;
  padding: 3rem;
  border-radius: 30px;
  border: 1px solid $border;
  box-shadow: inset 0 0 100px 40px rgb(255 255 255 / 100%);
  &-left {
    margin: auto;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../public/images/general/bg-pattern.png");
    background-size: auto;
    background-position: center;
    opacity: 0.7; // Adjust the opacity as needed
    z-index: -1; // Ensure it is behind the content
    border-radius: inherit; // Match the border-radius of the container
  }
}

.demo-video-iframe {
  border-radius: 25px;
}

////// responsive mobile //

@media screen and (max-width: 768px) {
  .demo-video-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 1rem;
  }
  .demo-video-iframe {
    border-radius: 25px;
    width: 100%;
    height: fit-content;
  }
}
