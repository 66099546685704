.about-core-values-list-container {
  margin: 3rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.about-core-values-list-title {
  @extend .paragraphTitle;
}
.about-core-values-list-text p {
  @extend .para;
}
.about-core-values-list-icon img {
  width: 30px;
  background-color: $icon-bg;
  border-radius: 10px;
  padding: 1rem;
}
.about-core-values-list-card {
  background-color: #fbfbfc;
  border-radius: 24px 24px 24px 24px;
  transform: translateY(0);
  padding: 72px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.about-core-values-list-card:hover {
  background-color: #ffffff;
  transition-duration: 0.3s;
  box-shadow: 0px 30px 120px 0px #343e611a;
}

@media screen and (max-width: 1280px) {
  .about-core-values-list-card {
    padding: 35px;
  }
}

@media screen and (max-width: 768px) {
  .about-core-values-list-container {
    grid-template-columns: 1fr;
  }
}
