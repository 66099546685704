.about-location-container-list{
    margin: 3rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.about-location-container-list-card{
    background-color:#fbfbfc;
    border-radius: 24px 24px 24px 24px;
    transform: translateY(0);
    padding: 72px;
}
.about-location-container-list-card:hover{
    background-color:  #FFFFFF;
    transition-duration: 0.3s;
    box-shadow: 0px 30px 120px 0px #343e611a;
}



.about-location-container-image{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:3rem;
}
.about-location-container-image img{
    width: 100%;
    height: auto;
}


    
  @media screen and (max-width: 768px) {
    .about-location-container-list{
 
        grid-template-columns: 1fr ;
        gap: 30px;
    }
    .about-location-container-list-card{
    
        padding:35px;
    }
  }
    
  