.price-card-section-outer-container {
  background-color: $background;
}
.priceCard-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 3rem;
}
.priceCard-content-card {
  border: 1px solid #e5e5e5;
  padding: 1.5rem;
  border-radius: 15px;
  background-color: $foreground;
  & h3 {
    @extend .para;
    font-weight: bold;
  }
  &-price {
    font-size: 2.5rem;
    font-weight: 700;
    color: #000;
    & h2 span {
      @extend .para;
    }
  }
  &-button button {
    border-radius: 10px;
    padding: 10px !important;
    width: 93%;
    margin: 1rem 0;
    background-color: $blue;
  }
  &-header {
    & h2 {
      @extend .sectionTitle;
      font-weight: bold;
      padding: 0 !important;
      margin: 10px 0;
    }

    & p {
      @extend .paragraphTitle;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-left: 10px !important;
      padding: 0.3rem 0 !important;

      & svg {
        color: $blue;
      }
    }
  }
  &-features {
    @extend .para;
    & ul li {
      margin: 0.75rem 0;
      list-style: none;
    }
    & svg {
      color: $brand-color;
    }
  }
}

.card2 {
  background-color: $blue;
  & h3 {
    color: white;
  }
  .priceCard-content-card-header {
    & p,
    svg {
      color: white;
    }
  }
  .priceCard-content-card-header h2 {
    @extend .sectionTitle;
    color: white;
  }
  .priceCard-content-card-features {
    & li,
    h3,
    svg {
      color: white;
      list-style: none;
    }
  }
  .priceCard-content-card-button {
    button {
      background-color: $brand-color;
    }
  }
}

@media screen and (max-width: 1280px) {
  .priceCard-content {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .priceCard-content {
    grid-template-columns: 1fr;
  }
}
