.newsletter-container {
  margin: 1rem 0;
}
.newsletter-form-control {
  position: relative;
  width: 70%;
  margin: 0.5rem 0;
}

.newsletter-input {
  border: 0;
  border-radius: 50px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  display: block;
  font-size: 14px;
  padding: 15px;
  width: 100%;
  &::placeholder {
    font-size: 16px;
    font-family: $font-family;
  }
}
.newsletter-container-text {
  @extend .para;
  color: white;
}
.newsletter-btn {
  background-color: $brand-color !important;
  border: 0;
  border-radius: 50px;
  color: #fff;
  font-size: 14px !important;
  padding: 12px 25px !important;
  margin-top: 10px;
}

.newsletter-input:focus,
.newsletter-btn:focus {
  outline: 0;
}

@media screen and (max-width: 768px) {
  .newsletter-form-control {
    width: 100%;
  }
  .newsletter-btn {
    position: relative;
    margin-top: 10px;
  }
}
