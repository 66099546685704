.customer-stories-inner-container {
  width: 90%;
  margin: auto;
}

.customer-stories-card-container {
  display: grid;
  justify-content: space-between;
  margin: 3rem 0;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  & a {
    text-decoration: none;
  }
}
.customer-stories-rect-card-container {
  margin: 3rem auto;
  background-color: $foreground;
  border-radius: 20px;
  & a {
    text-decoration: none;
    cursor: normal;
  }
}
.customer-stories-card {
  background-color: $background;
  border-radius: 20px;
  padding-bottom: 1rem;

  &-image {
    height: 250px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px 10px 0 0;
    }
  }

  &-title {
    margin: 0 1rem !important;
    padding-top: 1rem !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.customer-stories-rect-card {
  display: flex;
  width: 100%;
  align-items: center;
  &-image {
    width: 100%;
    height: 400px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px 0 0 20px;
    }
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 5% 5%;
    & a {
      text-decoration: none;
      font-weight: 800;
      font-size: 1rem;
      color: $brand-color;
      display: flex;
      align-items: center;
    }
  }
  &-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    color: $blue;
  }
}

.customer-stories-card-title {
  isplay: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (max-width: 1280px) {
  .customer-stories-card-container {
    grid-template-columns: 1fr 1fr;
  }
  .customer-stories-rect-card-container {
    margin: 3rem 0;
  }
  .customer-stories-rect-card {
    flex-direction: column;
    &-image {
      height: 300px;
      border-radius: 20px 20px 0 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .customer-stories-card-container {
    grid-template-columns: 1fr;
  }
  .customer-stories-rect-card-container {
    margin: 3rem 0;
  }
  .customer-stories-rect-card {
    flex-direction: column;
    &-image img {
      height: 300px;
      border-radius: 20px 20px 0 0;
    }
  }
}
