.articles-section-title {
  @extend .sectionMainTitle;
  margin: 0.2rem 0.1rem;
  color: $brand-color;
}
.articles {
  &-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    text-align: center;
  }
  &-container {
    display: flex;
    gap: 3rem;
    align-items: center;
  }
}

.articles-title {
  @extend .sectionTitle;
}
.articles-left-container {
  width: 100%;
}
.articles-right-container {
  width: 80%;
}
.articlesItem-card {
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
  align-items: center;
  text-align: start;
  &-image {
    width: 100%;
    height: auto;
    border-radius: 20px;
    overflow: hidden; // Ensure the image doesn't overflow the container
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s; // Move transition here for smoother effect
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &-date {
    @extend .para;
    color: $brand-color;
  }

  &-description {
    @extend .para;
  }

  &-title {
    @extend .paragraphTitle;
    padding: 0 !important;
    &:hover {
      color: $brand-color;
      transition: color 0.5s;
    }
  }
}

.articles-latest-article {
  &-image {
    width: 100%;
    border-radius: 20px;
    overflow: hidden; // Ensure the image doesn't overflow the container
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s; // Move transition here for smoother effect

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &-title {
    @extend .sectionTitle;
    text-align: start;
  }
  &-details {
    display: flex;
    gap: 1rem;
    text-align: start;
    justify-content: space-between;
    margin: 1.5rem;
    &-type {
      @extend .para;
      background-color: $background;
      padding: 0.2rem 1rem !important;
      border-radius: 10px;
    }
  }
}

/////responsive design for articles
/// 1. For mobile devices
/// 2. For tablets
/// 3. For desktops
/// 4. For large desktops

@media screen and (max-width: 1024px) {
  .articles {
    &-container {
      flex-direction: column;
    }
    &-right-container {
      width: 100%;
    }
  }
  .articlesItem-card {
    gap: 1rem;
    &-image {
      width: 100%;
      height: 200px;
    }
  }
}

@media screen and (max-width: 768px) {
  .articles {
    &-container {
      flex-direction: column;
    }
    &-right-container {
      width: 100%;
    }
  }
  .articlesItem-card {
    flex-direction: column;
    gap: 1rem;
    &-image {
      width: 100%;
      height: 200px;
    }
  }
}
