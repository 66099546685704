.industry-type-container {
  display: grid;
  grid-template-areas:
    "industry-type-header industry-0 industry-1"
    "industry-2 industry-3 industry-4";
}
.industry-type-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.industry-type-header-desc {
  @extend .paragraphTitle;
  display: flex;
  align-items: center;
  gap: 10px;
}
.industry-0 {
  border-top-left-radius: 15px;
}
.industry-1 {
  border-top-right-radius: 15px;
}
.industry-2 {
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}
.industry-4 {
  border-bottom-right-radius: 15px;
}
.industry-type-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid $border;
  padding: 3rem;
  &-icon {
    padding: 0.7rem;
    background-color: $icon-bg;
    width: fit-content;
    border-radius: 10px;
    color: $brand-color;
    display: flex;
    & img {
      width: 40px;
    }
  }
  & a {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &-nav {
    border: 1px solid $border;
    border-radius: 50%;
    width: fit-content;
    padding: 1rem;
    display: flex;

    & svg {
      color: #adadad;
    }
    &:hover {
      border: 1px solid $brand-color;
      transition: transform 0.3s;
      & svg {
        color: $brand-color;
        transition: transform 0.3s;
      }
    }
  }
}

///// responsive styles/////
///
///
/// //// width 1280px

@media (max-width: 1280px) {
  .industry-type-container {
    grid-template-areas:
      "industry-type-header industry-0 "
      "industry-1 industry-2"
      "industry-3 industry-4";
  }
  .industry-type-content {
    border-radius: 0;
  }
}

@media (max-width: 768px) {
  .industry-type-container {
    grid-template-areas:
      "industry-type-header"
      "industry-0"
      "industry-1"
      "industry-2"
      "industry-3"
      "industry-4";
  }
  .industry-type-content {
    padding: 1rem;
    border-radius: 0;
  }
  .industry-type-header {
    padding: 1rem;
  }
}
