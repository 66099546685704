.corporate-responsibility-banner-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;  
}




.corporate-responsibility-our-vision-content-wrapper {
  padding: 2rem;
  border: 1px solid $border;
  border-radius: 15px;
  box-shadow: $box-shadow;
  margin: 2rem 0;
  &-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    & img {
      border-radius: 15px;
      width: 100%;
      max-height: 400px;
      object-fit: cover;
      object-position: bottom;
    }
  }
}
