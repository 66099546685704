.sign-up-page-container {
  display: flex;
  gap: 30px;
  flex-direction: row-reverse;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  overflow-y: scroll;
}
.sign-up-form-header {
  padding-bottom: 30px;
}
.sign-up-form-header-title,
.subscriber-details-container-title {
  @extend .sectionTitle;
  color: $brand-color;
  text-align: start;
  font-size: 25px;
  padding: 0 !important;
}

.subscriber-details {
  display: flex;
  flex-direction: column;
}

.subscriber-details-pair {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: flex-end; */
  margin-bottom: 10px;
  align-items: end;
}

.subscriber-key {
  @extend .paragraphTitle;
  font-weight: 600;
}

.subscriber-value {
  @extend .para;
  flex-grow: 1;
  margin-left: 10px;
}

.sign-up-form-header-description {
  @extend .para;
  padding: 0 !important;
}
.sign-up-form-outer-container,
.subscriber-details-outer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.sign-up-form-inner-container {
  width: 100%;
}
.sign-up-form-inner-container::-webkit-scrollbar {
  width: 4px;
}

.sign-up-form-inner-container::-webkit-scrollbar-track {
  box-shadow: 0;
  border-radius: 0;
}

.sign-up-form-inner-container::-webkit-scrollbar-thumb {
  background: #72727214;
  border-radius: 55px;
}
.subscriber-details-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  // margin-top: 20px;
  gap: 20px;
  & button {
    width: 25% !important;
    margin-top: 10px !important;
    border-radius: 5px !important;
    padding: 13px 16px !important;
  }
}
#sign-up {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.signup-form-row-container {
  width: 100%;
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
}
.signup-checkbox {
  font-size: 13px !important;
}
.sign-up-form-company label:after,
.sign-up-name label:after,
.sign-up-email label:after {
  content: " *";
  color: red;
}

.signup-row-input,
.signup-country {
  margin: 0;
  width: 100%;
  padding: 5px 10px;
  display: block;
  border: none;
  border: none;
  background-image: linear-gradient(
    to right,
    #7e86a5,
    #ffffff
  ); /* Linear gradient */
  background-position: 0 100%;
  background-size: 100% 1px; /* Adjust the height of the border */
  background-repeat: no-repeat;
  &:focus {
    outline: none;
  }
  &::placeholder {
    @extend .para;
    color: #999eb0;
    font-size: 15px;
  }
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
  & label {
    color: $blue;
    font-size: 15px;
  }
}

.sign-up-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  overflow: hidden;

  & img {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 8px;
    border-radius: 15px;
  }
}
#sign-up-form-submit {
  width: 40% !important;
  margin-top: 10px !important;
  border-radius: 5px !important;
  padding: 10px 16px !important;
  background-color: $brand-color !important;
}
@media screen and (max-width: 1280px) {
  .sign-up-image-container {
    max-width: 40%; 
  }
}
@media screen and (max-width: 768px) {
  .sign-up-page-container {
    flex-direction: column;
    overflow-y: scroll;
    padding: 30px;
    gap: 0;
    height: 100%;
    max-height: 800px;
  }
  .sign-up-form-outer-container {
    justify-content: center;
  }
  .sign-up-image-container {
    width: 50%;
    order: -1;
  }
  .signup-form-row-container {
    grid-template-columns: 1fr;
  }
  .signup-row-input,
  .signup-country {
    width: 100%;
  }
  #sign-up-form-submit {
    width: 100% !important;
  }
}
