.generative-ai-banner-container {
  padding: 1rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.generative-ai-banner-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.generative-ai-achievement {
  width: 150px;
}

.generative-ai-banner-title h1 span {
  color: $brand-color;
}

.generative-ai-banner-image {
  width: 100%;
  & img {
    width: 100%;
    border-radius: 20px;
  }
}

.generative-ai-banner-button {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .generative-ai-banner-container {
    flex-direction: column;
  }
}
