.voice-bot-infographic-container {
  display: flex;
  align-items: center;
  background-color: #f7f9f7;
  border-radius: 30px;
  padding: 30px;
}
.voice-bot-infographic-content {
  width: 100%;
  &-title {
    @extend .bannerTitle;
    margin-bottom: 20px;
  }
  &-description {
    @extend .para;
    margin-bottom: 20px;
  }
}
.voice-bot-infographic-image {
  width: 100%;
  & img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
    .voice-bot-infographic-container  {
        flex-direction: column-reverse;
        gap: 30px;
    }
  }
  