.modal-container {
  max-width: 350px;
  position: fixed; /* Change to fixed */
  background: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  top: 50%; /* Keep it centered vertically */
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 2000;
}

.modal-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-message {
    text-align: center;
  }
}

.modal-content-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: $blue;
}
.modal-content-btn {
  width: 80%;
  margin: 20px;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #ff0000;
  color: #ff0000;
  cursor: pointer;
}

.modal-content-header-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: red;
  cursor: pointer;
}

.modal-content-title-fail {
  color: red;
}
.modal-content-btn-fail {
  width: 80%;
  margin: 20px;
  padding: 6px;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid red;
  color: red;
  cursor: pointer;
}
