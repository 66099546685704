.career-container-info {
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
  text-align: center;

  &-title {
    @extend .paragraphTitle;
    text-align: start;
  }
  &-subtitle {
    @extend .sectionTitle;
    color: $brand-color;
    padding-top: 0.5rem !important;
    text-align: start;
    margin-left: 10px;
  }
}

.career-job-list-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.career-job-list-header {
  padding: 1rem 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: start;
  border-bottom: 1px solid $blue;
  text-align: center;
  color: $blue;
  @extend .para;
  font-weight: 600;
}
.career-job-list-card {
  padding: 1rem 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: start;
  border-bottom: 1px solid #dddddd;
  text-align: center;
  // border-radius: 10px;
  // box-shadow: 0px 30px 120px 0px #343e611a;
}
.career-job-list-logo {
  display: flex;
  justify-content: center;
}
.career-job-list-title {
  @extend .para;
  font-weight: 500;
  text-align: center;
}
.career-job-list-job-type {
  @extend .para;
  text-align: center;
}
.career-job-list-job-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.career-job-list-job-button button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 25px !important;
  font-size: 16px;
  border: none;
  background-color: #fff;
  color: $brand-color;
  cursor: pointer;
  border-radius: 5px;
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    &::before {
      width: 60%;
    }
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: $brand-color;
    transition: width 0.3s ease-in-out;
  }
}

.career-job-list-job-show-more-button button {
  @extend .bannerSecondaryButton;
}

@media screen and (max-width: 768px) {
  .career-container-info {
    margin: 0 !important;
  }
  .career-job-list-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .career-job-list-header {
    display: none;
  }

  .career-job-list-card {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  .career-job-list-job-button {
    justify-content: flex-end;
  }
}
//////////////////////////////////////////////////////// Job Details Drawer styles ///////////////////////////////////

.ant-drawer-content-wrapper {
  width: 700px !important;
}
.drawer-job-details p {
  @extend .para;
  & span {
    @extend .paragraphTitle;
    margin-right: 10px !important;
  }
}
.drawer-job-details-content {
  @extend .para;
  & span {
    @extend .paragraphTitle;
    margin-right: 10px !important;
  }
  ul {
    padding: 1rem;
    list-style-type: disc;
  }
}
.drawer-job-details h2 {
  @extend .sectionTitle;
  margin-bottom: 20px;
}

.drawer-job-details-button {
  display: flex;
  justify-content: center;
  button {
    @extend .bannerSecondaryButton;
  }
}

////////////////////////////////////////////job benefits styles///////////////////////////////////////////////

.career-benefits-inner-container {
  display: flex;
  gap: 30px;
  align-items: center;
  border: 1px solid $border;
  border-radius: 20px;
  // background-color: $foreground;
}

.career-benefits-image-wrapper {
  width: 100%;
  height: 1000px;
  overflow: hidden;
  border-radius: 20px 0 0 20px;
  background-color: #efeff18c;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: inherit;
  }
}

.career-benefits-content-wrapper {
  width: 100%;
  padding: 2rem;

  & h3 {
    display: flex;
    align-items: center;
    & svg {
      margin-right: 10px;
    }
  }
}

////////////////////////////////////////////job our team///////////////////////////////////////////////
.career-our-teams-outer-container {
  background-color: $background;
}

.career-our-teams-header {
  text-align: center;
  margin: 0 auto 3rem;
  max-width: 33rem;
}

.career-our-team-card {
  display: flex !important;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  width: 85% !important;
  margin: auto;
  padding: 4rem;
  border: 1px solid $border;
  border-radius: 20px;
  background-color: $foreground;
  &-image img {
    max-width: 250px;
    // width: 100%;
    border-radius: 15px;
    object-fit: cover;
    aspect-ratio: 5/5;
  }
  .career-our-team-card-icon {
    position: absolute;
    width: 150px;
    height: fit-content;
    z-index: -1;
    color: $border;
    top: 3rem;
  }
  &-content {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    &-designation {
      @extend .para;
      font-weight: 500;
      margin: 0 0 12px 0 !important;
    }
  }
  & h3,
  p {
    padding: 0 !important;
  }
}

////////////////////////////////////////////job openings page///////////////////////////////////////////////
.job-openings-title {
  margin-left: "auto";
  margin-right: "auto";
  text-align: "start";
  max-width: "80rem";
  display: flex;
  align-items: center;
  padding: 8rem 0;
  width: "100%";
  background-image: url("../../../../public/images/banner-images/job-oppenings.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  & h2 {
    margin-left: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .job-openings-title {
    padding: 4rem 0;
    justify-content: center;
    & h2 {
      margin-left: 0;
    }
  }
}

////////////////////////////////////////////career job interest///////////////////////////////////////////////
.career-job-interest-form-outer-container {
  background-color: $background;
}

.career-job-interest-form-inner-container {
  background-color: $foreground;
  border-radius: 20px;
}

.career-job-interest-inner-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  border: 1px solid $border;
  border-radius: 20px;
  background-color: $background;
  padding: 2rem;
  & h2 {
    margin: 0 !important;
  }
  & p {
    @extend .para;
    text-align: center;
  }
  & button {
    @extend .bannerSecondaryButton;
  }
}
.career-job-interest-form-container {
  padding: 2rem;
  background-color: $foreground;
  border-radius: 20px;
}
.career-job-interest-form-row-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 2rem 0;
}
.career-job-interest-form-upload-label {
  height: auto !important;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  text-align: center !important;
  color: #999eb0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 99% !important;
}
.career-job-interest-form-select {
  height: 50px !important;
  width: 99% !important;
}
.career-job-interest-form-select .css-13cymwt-control {
  width: 100% !important;
  padding: 7px;
  border: none !important;
}

.css-t3ipsp-control {
  width: 100% !important;
  padding: 7px;
  box-shadow: none !important;
  border: none !important;
  &:hover {
    border: none !important;
  }
}
.css-1nmdiq5-menu {
  width: 100% !important;
  padding: 7px;
}

.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.inputfile + label * {
  pointer-events: none;
}

.career-job-interest-form-hero-section {
  padding: 3rem 0;
}
.career-job-interest-form-submit-button {
  & button {
    width: 100% !important;
    border-radius: 5px !important;
    background-color: $blue !important;
  }
}
//// responsive styles ////

@media screen and (max-width: 1280px) {
  .career-container {
    display: flex;
    flex-direction: column;
  }
  .career-container-info {
    width: 100%;
    margin: 2rem;
  }
  .career-job-list-job-show-more-button {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .career-benefits-inner-container {
    flex-direction: column;
    // background-color: $foreground;
    border: none;
    gap: 0;
  }

  .career-benefits-image-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
      border-radius: 20px 20px 0 0;
      object-fit: contain;
    }
  }
  .career-our-team-card {
    width: 85% !important;
    padding: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .ant-drawer-content-wrapper {
    width: auto !important;
  }

  .career-benefits-inner-container {
    flex-direction: column;
    // background-color: $foreground;
    border: none;
    gap: 0;
  }

  .career-benefits-image-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
      border-radius: 20px 20px 0 0;
      object-fit: contain;
    }
  }
  .career-our-team-card {
    flex-direction: column;
    width: 85% !important;
    padding: 1rem;
    &-content {
      flex-direction: column;
    }
  }
  .career-our-teams-hero-image {
    height: 120px;
  }
  .career-our-teams-header-text {
    max-width: 100%;
  }

  .career-job-interest-inner-container {
    padding: 1rem;
  }
  .career-benefits-content-wrapper {
    padding: 1rem;
  }
}
