.library-details-navigation-container {
  display: flex;
  justify-content: center;
  box-shadow: 0px 30px 120px 0px #343e611a;
  border-radius: 10px;
}
.library-details--nav {
  display: flex;
}
.library-details-nav {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  background-color: #fff;
  padding: 5px 20px;
  border-radius: 10px;
  box-shadow: 0 10px 40px rgba(159, 162, 177, 0.8);
}
.library-section-title {
  padding: 10px;
  & h1 {
    padding: 0 !important;
  }
}
.library-details-nav-item {
  color: $blue;
  padding: 20px;
  text-decoration: none;
  font-size: 20px;
  transition: 0.3s;
  margin: 0 6px;
  z-index: 1;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: $brand-color;
    border-radius: 8px 8px 0 0;
    opacity: 0;
    transition: 0.3s;
  }
}

.library-details-nav-item.active {
  color: $brand-color;
}

.library-details-nav-item.active:before {
  opacity: 1;
  bottom: 0;
}

.library-details-nav-item.active:hover {
  color: $brand-color;
}

.library-details-nav-indicator {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  transition: 0.4s;
  height: 5px;
  z-index: 1;
  border-radius: 8px 8px 0 0;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin: 20px;
  background-color: $background;
  padding: 1rem;
  border-radius: 15px;
}

.tab-button {
  padding: 15px;
  cursor: pointer;
  border: none;
  // background-color: ;
  color: $blue;
  text-align: center;
  width: 50%;
  transition: background-color 0.3s;
  border-radius: inherit;
}

.tab-button.active {
  background-color: $foreground;
  font-weight: bold;
}

@media (max-width: 580px) {
  .library-details-nav {
    overflow: auto;
  }
}

// .library-details-items {
//   //   background-color: #f2eee3;
//   margin: 3rem 0;
// }

.library-details-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0 50px;
}

.library-type {
  padding: 0 20px;
  border-radius: 5px;
}

//////////////////// broucher //////////////////////

.library-main-container {
  background-color: #f7f7f7;
  margin: 35px 0;
  border: 1px solid #f1f1f1;
  border-radius: 15px;
  display: flex;
  position: relative;
  & a {
    text-decoration: none;
  }
  & h3 {
    padding: 5px 20px 15px 20px !important;
  }
}
.library-container {
  width: 100%;
}

.library-image-container {
  width: 100%;
  height: 240px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px 15px 0 0;
  }
}

@media only screen and (max-width: 1280px) {
  .library-details-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .library-details-items {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .tabs {
    flex-direction: column;
    align-items: center;
  }

  .tab-button {
    width: 100%;
    text-align: center;
  }
}
