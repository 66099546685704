.review-main-card-container {
  // background-image: linear-gradient(to left, #dddee4, #e6e6eb, #eeeef1, #f7f7f8, #ffffff);
background-color: $bg-color;
border-radius: 20px;
}
.review-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem; 
  gap: 50px;
}

.review-card-container-title{
  color: #fff;
}

.review-card:nth-of-type(1) {
  background-color: #fee9e6;
}
.review-card:nth-of-type(2) {
  background-color: #c2e0ee;
}
.review-card {
  height: auto;
  max-width: 400px;
  padding: 30px;
  border-radius: 20px;
  transition: transform 0.5s;
  &:hover {
    box-shadow: 0px 30px 120px 0px #343e611a;
    transform: scale(1.1);
  }
}

.review-card-header {
  @extend .para;
  color: $blue;
  font-weight: 700;
}
.review-card-content {
  @extend .paragraphTitle;
  color: #000;
  font-size: 20px;
  padding: 0 !important;
}

@media screen and (max-width: 768px) {
  .review-card-container {
    flex-direction: column;
  }
  .review-card iframe {
    width: 100%;
  }
}
