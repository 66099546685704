.solution-banner-inner-bg {
  background-image: url("../../../../public/images/general/beams-basic.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 90%;
  margin: auto;
  border-radius: 20px;
}
.solution-banner-inner-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 610px;
  margin: auto;
  border: 1px solid $border;
  border-radius: 20px;
  // background-image: linear-gradient(
  //     to right,
  //     rgb(229, 233, 237) 1px,
  //     transparent 1px
  //   ),
  //   linear-gradient(to bottom, rgb(229, 233, 237) 1px, transparent 1px);
  // background-size: 10.5rem 10.5rem;
  // background-position: center center;
  // box-shadow: inset white 0px 0px 600px -120px;
}

.solution-byIndustry-banner-image {
  width: 100%;
  img {
    width: 100%;
    border-radius: 20px 0 0 20px;
  }
}

.solution-banner-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 4rem;
  gap: 1rem;
}
.solution-banner-button {
  width: fit-content;
  display: flex;
  gap: 1rem;
}
.solution-banner-image {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.solution-banner-image-col-image {
  width: 180px;
  height: 240px;
}
.solution-banner-image-col1 {
  padding-top: 16rem;
}
.solution-banner-image-col2 {
  padding-top: 5rem;
}
.solution-banner-image-col3 {
  width: 130px;
  overflow: hidden;
}
.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 11px;
  margin-top: 15px;
}

@media screen and (max-width: 1280px) {
  .solution-banner-inner-container {
    height: 100%;
    flex-direction: column;
    // margin: 3rem 0;
    padding: 2rem 0;
    border: none;
  }
  .solution-banner-content {
    width: auto;
    padding: 0 3rem;
  }
  .solution-banner-image {
    width: auto;
  }
  .solution-banner-inner-bg {
    width: 100%;
  }
  .solution-banner-inner-container {
    background-image: none;
  }
}
@media screen and (max-width: 768px) {
  .solution-banner-inner-container {
    height: 100%;
    flex-direction: column;
    border: none;
  }
  .solution-banner-content {
    width: auto;
    padding: 0 1rem;
  }
  .solution-banner-image {
    width: auto;
  }
  .solution-banner-inner-bg {
    width: 100%;
  }
  .solution-banner-inner-container {
    background-image: none;
  }
}
