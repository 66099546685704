.about-teams-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-evenly;
  align-items: center;
  justify-items: center;
  margin-top: 3rem;
  gap: 2rem;
}
.about-teams-card {
  width: 340px;
  height: 400px;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  transition: 0.3s;
  flex-direction: column;
  border-radius: 30px;
  &-cover {
    height: 300px;
    overflow: hidden;
  }
  &-avatar {
    width: 100%;
  }
  &:hover{
    box-shadow: 0px 30px 120px 0px #343e611a;
  }
}

.about-teams-card-fullname {
  @extend .sectionTitle;
  font-size: 22px;
  text-align: center;
  white-space: nowrap;
  margin: 0;
}

.about-teams-card-jobtitle {
  @extend .para;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

@media screen and (max-width: 1280px) {
  .about-teams-card-container {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
}

@media screen and (max-width: 768px) {
  .about-teams-card-container {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .about-teams-card {
    width: 300px;
    height: 350px;
  }
}
