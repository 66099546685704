.partners-logo-inner-container {
  background-color: $background;
  width: 90% !important;
  border-radius: 30px;
}

.partners-logo-container {
  padding: 3rem 0;
  & .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 1rem 0;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;

    & img {
      background-color: white;
      padding: 1rem;
      border-radius: 15px;
      margin: 20px;
      max-width: 170px;
      max-height: 170px;
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .partners-logo-container {
    & .logo {
      gap: 10px;
      flex-direction: column;
    }
  }
}
