.ourPartnerListContainer {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 50px;
}

.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards_item {
  display: flex;
  padding: 1rem;
}

.card_image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.card_image img {
  width: 50%;
  height: 100%;
}

.cards_item {
  width: 350px;
  height: 430px;
}

.card {
  background-image: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid #e8e8e8;
}

.card:hover {
  box-shadow: 0 30px 120px 0 rgba(52,62,97,.102);
}

.card_text p {
  font-size: 14px;
}
.card_content {
  position: relative;
  max-height: 290px;
  overflow-y: scroll;
  padding: 20px;
}

.card_content::-webkit-scrollbar {
  width: 4px;
}

.card_content::-webkit-scrollbar-track {
  box-shadow: 0;
  border-radius: 0;
}

.card_content::-webkit-scrollbar-thumb {
  background: #72727214;
  border-radius: 55px;
}

.card_title {
  @extend .paragraphTitle;
  position: relative;
  padding-bottom: 10px !important;
  text-align: center;
}

.card_title::after {
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f05742;
  content: "";
}

hr {
  margin: 24px auto;
  width: 50px;
  border-top: 2px solid #f05742;
}
.card_text p {
  @extend .para;
}
.card_text p:last-child {
  margin: 0;
}

.card-meta {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @extend .para;
  padding: 20px !important;
  border-top: 2px solid #f1f1f1;
  &-website a {
    text-decoration: none;
    color: $light-blue;
  }
}

@media screen and (max-width: 1280px) {
  .ourPartnerListContainer {
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
  .cards_item {
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .ourPartnerListContainer {
    grid-template-columns: 1fr;
  }
  .cards_item {
    width: auto;
    height: auto;
  }
}
