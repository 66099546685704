.why-choose-us-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  &-list {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
}
.why-choose-us-content-left {
  width: 80%;
  margin: 0 auto;
}
.why-choose-us-content-right {
  width: 100%;
}
